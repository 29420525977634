export default () => {

    //(1)最初にnewsを指定した数だけ取得するスクリプト
    //(2)moreボタンを押したときにnewsを指定した数だけ追加で取得するスクリプト


    //ニュースを初期でなんこだすか:0を含むので出したい数-1
    let initialNews = 3
    //moreボタンを押した時に出したい数
    let moreNews = 3

    //要素の取得
    const newsUL = document.getElementById("news-ul") //news.html内のul要素を取得
    const moreNewsButton = document.querySelector('.page-news-more')//moreボタンを取得

    //jsonファイルのpathを指定
    const newsJsonURL = "./news.json"

    //ウィンドウサイズが768以上ならnewsを全部出す
    if(window.innerWidth > 768){

    //PCの場合:768以上の場合
    //=======================================================================

        //JSONファイルからworksを取得
        fetch(newsJsonURL)
        .then(response => response.json())
        .then(data => {

            //日付順に並び替える関数
            let result = data.sort(function(a, b){
                return (a.date > b.date) ? -1:1;
            })

            //lengthを取得
            let NewsDataLength = data.length

            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= NewsDataLength; i++){

                // 要素を作る
                const liELe = document.createElement("li")
                const aELe = document.createElement("a")
                const dateEle = document.createElement("p")
                const descriptionEle = document.createElement("p")

                //pタグのテキストを挿入する
                dateEle.textContent = data[i].date
                dateEle.classList.add('page-news-date')

                descriptionEle.textContent = data[i].description
                descriptionEle.classList.add('page-news-text')

            
                //aタグにモーダル用のclassを追加する
                aELe.href = data[i].url
                //a要素にblunkとrel="noopener noreferrer"を追加する
                aELe.setAttribute('target', '_blank');
                aELe.setAttribute('rel', 'noopener noreferrer');
                //aタグにappendする
                aELe.appendChild(dateEle)
                aELe.appendChild(descriptionEle)
                //liタグにaタグをappendする
                liELe.appendChild(aELe)
                //liをulにappendする
                newsUL.appendChild(liELe)

            }
        })
        .catch(err => console.log(err))
    }


    //スマホの場合
    //=======================================================================

    if(window.innerWidth < 768){

        //JSONファイルからworksを取得
        fetch(newsJsonURL)
        .then(response => response.json())
        .then(data => {

            //lengthを取得
            let NewsDataLength = data.length

            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= initialNews; i++){

                let result = data.sort(function(a, b){
                    return (a.date > b.date) ? -1:1;
                })

                // 要素を作る
                const liELe = document.createElement("li")
                const aELe = document.createElement("a")
                const dateEle = document.createElement("p")
                const descriptionEle = document.createElement("p")

                //pタグのテキストを挿入する
                dateEle.textContent = data[i].date
                dateEle.classList.add('page-news-date')

                descriptionEle.textContent = data[i].description
                descriptionEle.classList.add('page-news-text')

            
                //aタグにモーダル用のclassを追加する
                aELe.href = data[i].url
                //a要素にblunkとrel="noopener noreferrer"を追加する
                aELe.setAttribute('target', '_blank');
                aELe.setAttribute('rel', 'noopener noreferrer');
                // aELe.href = "#"
                //aタグにappendする
                aELe.appendChild(dateEle)
                aELe.appendChild(descriptionEle)
                //liタグにaタグをappendする
                liELe.appendChild(aELe)
                //liをulにappendする
                newsUL.appendChild(liELe)

                //iがnewsNewsDataLengthを越えたらmoreボタンを消す
                if( i === NewsDataLength-1){
                    $(function () {
                        $('.page-news-more').fadeOut();
                    });
                }
            }
        })
        .catch(err => console.log(err))

    }



    //moreボタンを押した時の処理
    if(!moreNewsButton){return}
    moreNewsButton.addEventListener('click', ()=>{

        //JSONファイルからworksを取得
        fetch(newsJsonURL)
        .then(response => response.json())
        .then(data => {

            // lengthを取得
            let NewsDataLength = data.length

            //dataLengthまで以下の処理を繰り返す
            for(let i = initialNews+1; i <= initialNews +moreNews; i++){
            // for(let i = 0; i <= NewsDataLength; i++){

                // 要素を作る
                const liELe = document.createElement("li")
                const aELe = document.createElement("a")
                const dateEle = document.createElement("p")
                const descriptionEle = document.createElement("p")

                //pタグのテキストを挿入する
                dateEle.textContent = data[i].date
                dateEle.classList.add('page-news-date')

                descriptionEle.textContent = data[i].description
                descriptionEle.classList.add('page-news-text')

            
                //aタグにモーダル用のclassを追加する
                aELe.href = data[i].url
                //a要素にblunkとrel="noopener noreferrer"を追加する
                aELe.setAttribute('target', '_blank');
                aELe.setAttribute('rel', 'noopener noreferrer');
                // aELe.href = "#"
                //aタグにappendする
                aELe.appendChild(dateEle)
                aELe.appendChild(descriptionEle)
                //liタグにaタグをappendする
                liELe.appendChild(aELe)
                //liをulにappendする
                newsUL.appendChild(liELe)
                // initialNews++


                //iがnewsNewsDataLengthを越えたらmoreボタンを消す
                if( i === NewsDataLength-1){
                    $(function () {
                        $('.page-news-more').fadeOut();
                    });

                }
                
            }
            
        })
        .catch(err => console.log(err))
        
        // initialNews = initialNews+moreNews
        
        const countUp = () => {
            initialNews = initialNews+moreNews


        }
        setTimeout(countUp, 1000)

        
        


    })






}