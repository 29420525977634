'use strict'

import hamburger from './hamburger.js'
import modal from './modal.js'
import getNews from './get-news.js'
import getTopNews from './get-top-news.js'
import getWorks from './get-works.js'
import getTopWorks from './get-top-works.js'
import getWorksModal from './get-works-modal.js'
// import moreNews from './more-news.js'


hamburger()
modal()
getNews()
getTopNews()
getWorks()
getTopWorks()
getWorksModal()
// moreNews()