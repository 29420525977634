export default () => {

    //(1)最初にnewsを指定した数だけ取得するスクリプト

    //ニュースを初期でなんこだすか:0を含むので出したい数-1
    let topInitialNews = 3;

    //要素の取得
    const topNewsUL = document.getElementById("top-news-ul") //news.html内のul要素を取得

    //jsonファイルのpathを指定
    const topNewsJsonURL = "./news.json"

    //initialNewsの数だけnewsを出す
    if(window.innerWidth > 768){

    //PCの場合:768以上の場合
    //=======================================================================

        //JSONファイルからworksを取得
        fetch(topNewsJsonURL)
        .then(response => response.json())
        .then(data => {

            //日付順に並び替える関数
            let result = data.sort(function(a, b){
                return (a.date > b.date) ? -1:1;
            })

            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= topInitialNews; i++){

                // 要素を作る
                const topLiELe = document.createElement("li")
                const topAELe = document.createElement("a")
                const topDateEle = document.createElement("p")
                const topDescriptionEle = document.createElement("p")

                //pタグのテキストを挿入する
                topDateEle.textContent = data[i].date
                topDateEle.classList.add('page-top-news-date')
                topDescriptionEle.textContent = data[i].description
                topDescriptionEle.classList.add('page-top-news-text')

            
                //aタグにモーダル用のclassを追加する
                topAELe.href = data[i].url
                topAELe.setAttribute('href', './news.html');
                //aタグにappendする
                topAELe.appendChild(topDateEle)
                topAELe.appendChild(topDescriptionEle)
                //liタグにaタグをappendする
                topLiELe.appendChild(topAELe)
                //liをulにappendする
                topNewsUL.appendChild(topLiELe)
            }
        })
        .catch(err => console.log(err))
    }


    //スマホの場合
    //=======================================================================

    if(window.innerWidth < 768){

        //JSONファイルからworksを取得
        fetch(topNewsJsonURL)
        .then(response => response.json())
        .then(data => {


            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= topInitialNews; i++){

                let result = data.sort(function(a, b){
                    return (a.date > b.date) ? -1:1;
                })

                // 要素を作る
                const topLiELe = document.createElement("li")
                const topAELe = document.createElement("a")
                const topDateEle = document.createElement("p")
                const topDescriptionEle = document.createElement("p")

                //pタグのテキストを挿入する
                topDateEle.textContent = data[i].date
                topDateEle.classList.add('page-top-news-date')
                topDescriptionEle.textContent = data[i].description
                topDescriptionEle.classList.add('page-top-news-text')

            
                //aタグにモーダル用のclassを追加する
                topAELe.href = data[i].url
                topAELe.setAttribute('href', './news.html');
                //aタグにappendする
                topAELe.appendChild(topDateEle)
                topAELe.appendChild(topDescriptionEle)
                //liタグにaタグをappendする
                topLiELe.appendChild(topAELe)
                //liをulにappendする
                topNewsUL.appendChild(topLiELe)
            }
        })
        .catch(err => console.log(err))
    }

}