export default () => {


    const url = "./works.json"

    // const modalTitleArray = []
    // const modalDescriptionArray = []


    
    const delay = ()=> {
        //モーダルの取得

        //サムネ取得
        let modalImage = document.querySelector('.works-modal-thumbnail')//サムネ取得
        let modalTitle = document.querySelector('#works-modal-title')//タイトル取得
        let modalCompanyName = document.getElementById('works-modal-company')
        let modalDescription = document.querySelector('.works-modal-description')//説明取得
        let modalDate = document.querySelector('.works-modal-date')//日付取得

        //worksのmodalがクリックされた時の処理
        $(document).on("click", ".works-modal-open", function(event) {

            modalImage = document.querySelector('.works-modal-thumbnail')//サムネ取得
            modalTitle = document.querySelector('#works-modal-title')//タイトル取得
            modalCompanyName = document.getElementById('works-modal-company')
            modalDescription = document.querySelector('.works-modal-description')//説明取得
            modalDate = document.querySelector('.works-modal-date')//日付取得


            // let modalNum = event.target.id

            //jsonから取得する
            fetch(url)
            .then(response => response.json())
            .then(data => {

                modalImage.src = data[event.target.id].thumbnail
                modalTitle.textContent = data[event.target.id].title
                modalCompanyName.textContent = data[event.target.id].company
                modalDescription.textContent = data[event.target.id].modalDescription
                modalDate.textContent = data[event.target.id].showDate

            })
            .catch(err => console.log(err))
        });

        

    }
    setTimeout(delay, 1000)
}