export default () => {

    //(1)最初にworksを指定した数だけ取得するスクリプト

    //worksを初期でなんこだすか:0を含むので出したい数-1
    let topInitialWorks = 3
    //要素の取得
    const topWorksUl = document.getElementById("top-works-ul") //works.html内のul要素を取得
    let topWorksATag = document.querySelector('.js-href');

    //jsonファイルのpathを指定
    const url = "./works.json"

    if(window.innerWidth > 768){

    //PCの場合:768以上の場合
    //=======================================================================
        //JSONファイルからworksを取得
        fetch(url)
        .then(response => response.json())
        .then(data => {
    
    
            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= topInitialWorks; i++){
    
                // 要素を作る
                const topWorksLiELe = document.createElement("li")
                const topWorksAELe = document.createElement("a")
                const topWorksImgELe = document.createElement("img")
                const topWorksH4Ele = document.createElement("h4")
                const topWorksPEle = document.createElement("p")
    
                //pタグのテキストを挿入する
                topWorksPEle.textContent = data[i].company
                //h4タグのテキストを挿入する
                topWorksH4Ele.textContent = data[i].title
                //imgタグのpathを挿入する
                topWorksImgELe.src = data[i].thumbnail
                //aタグにモーダル用のclassを追加する
                topWorksAELe.classList.add('works-modal-open')
                // topWorksAELe.setAttribute('href', './works.html');

                //aタグに順番のためのidを付与する
                topWorksAELe.id = i
                topWorksAELe.classList.add('js-href')
    
                // topWorksAELe.href = "#"
                //aタグにappendする
                topWorksAELe.appendChild(topWorksImgELe)
                topWorksAELe.appendChild(topWorksH4Ele)
                topWorksAELe.appendChild(topWorksPEle)
                //liタグにaタグをappendする
                topWorksLiELe.appendChild(topWorksAELe)
                //liをulにappendする
                topWorksUl.appendChild(topWorksLiELe)
    
            }
        })
        .catch(err => console.log(err))
    }

    //スマホの場合
    //=======================================================================
    if(window.innerWidth < 768){
        //JSONファイルからworksを取得
        fetch(url)
        .then(response => response.json())
        .then(data => {


            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= topInitialWorks; i++){

                // 要素を作る
                const topWorksLiELe = document.createElement("li")
                const topWorksAELe = document.createElement("a")
                const topWorksImgELe = document.createElement("img")
                const topWorksH4Ele = document.createElement("h4")
                const topWorksPEle = document.createElement("p")

                //pタグのテキストを挿入する
                topWorksPEle.textContent = data[i].company
                //h4タグのテキストを挿入する
                topWorksH4Ele.textContent = data[i].title
                //imgタグのpathを挿入する
                topWorksImgELe.src = data[i].thumbnail
                //aタグにモーダル用のclassを追加する
                topWorksAELe.classList.add('works-modal-open')
                //aタグに順番のためのidを付与する
                topWorksAELe.id = i
                topWorksAELe.classList.add('js-href')

    
                // topWorksAELe.href = "#"
                //aタグにappendする
                topWorksAELe.appendChild(topWorksImgELe)
                topWorksAELe.appendChild(topWorksH4Ele)
                topWorksAELe.appendChild(topWorksPEle)
                //liタグにaタグをappendする
                topWorksLiELe.appendChild(topWorksAELe)
                //liをulにappendする
                topWorksUl.appendChild(topWorksLiELe)
            }
        })
        .catch(err => console.log(err))
    }

        //aたぐがクリックされた時の処理
        $(document).on("click", ".js-href", function(event) {
            window.location.href = './works.html'
        });
}