export default () => {

   //jsonデータを読み込むために、少し遅らせる
    const delayModal = () => {

      
      // コンタクトのmodal
      
      $(function () {
        //asideのcontactをクリックした時
        $('.modal-open').click(function (e) {
          e.preventDefault();
          $('#overlay, .modal-window').fadeIn();
        });
        $('.modal-close').click(function () {
          $('#overlay, .modal-window').fadeOut();
        });
      });
      
      
      // worksのmodal
      
      $(function () {

        $(document).on("click", ".works-modal-open", function(e) {
          e.preventDefault();
          $('#works-overlay, .works-modal-window').fadeIn();
        });

        $(document).on("click", ".works-modal-close", function(e) {
          $('#works-overlay, .works-modal-window').fadeOut();
        });



        // $('.works-modal-open').click(function (e) {
        //   e.preventDefault();
        //   $('#works-overlay, .works-modal-window').fadeIn();
        // });
        // $('.works-modal-close').click(function () {
        //   $('#works-overlay, .works-modal-window').fadeOut();
        // });
        


        
        
        
      });
      
    }
    setTimeout(delayModal, 1000)   //jsonデータを読み込むために、少し遅らせる
}