export default () => {

    //(1)最初にworksを指定した数だけ取得するスクリプト
    //(2)moreボタンを押したときにworksを指定した数だけ追加で取得するスクリプト
    //windowサイズが768以上の場合はマックスで出しちゃう

    //worksを初期でなんこだすか:0を含むので出したい数-1
    let initialWorks = 3
    //worksボタンを押した時に出したい数
    let moreWorks = 3

    //要素の取得
    const ul = document.getElementById("works-ul") //works.html内のul要素を取得
    const moreWorksButton = document.querySelector('.page-works-more')//moreボタンを取得

    //jsonファイルのpathを指定
    const url = "./works.json"



    if(window.innerWidth > 768){

    //PCの場合:768以上の場合
    //=======================================================================
        //JSONファイルからworksを取得
        fetch(url)
        .then(response => response.json())
        .then(data => {
    
            //lengthを取得
            let dataLength = data.length
    
            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= dataLength; i++){
    
                // 要素を作る
                const liELe = document.createElement("li")
                const aELe = document.createElement("a")
                const imgELe = document.createElement("img")
                const h4Ele = document.createElement("h4")
                const pEle = document.createElement("p")
    
                //pタグのテキストを挿入する
                pEle.textContent = data[i].company
                //h4タグのテキストを挿入する
                h4Ele.textContent = data[i].title
                //imgタグのpathを挿入する
                imgELe.src = data[i].thumbnail
                //aタグにモーダル用のclassを追加する
                aELe.classList.add('works-modal-open')
                //aタグに順番のためのidを付与する
                aELe.id = i
    
                aELe.href = "#"
                //aタグにappendする
                aELe.appendChild(imgELe)
                aELe.appendChild(h4Ele)
                aELe.appendChild(pEle)
                //liタグにaタグをappendする
                liELe.appendChild(aELe)
                //liをulにappendする
                ul.appendChild(liELe)
    
            }
        })
        .catch(err => console.log(err))
    }





    //スマホの場合
    //=======================================================================
    if(window.innerWidth < 768){
        //JSONファイルからworksを取得
        fetch(url)
        .then(response => response.json())
        .then(data => {

            //lengthを取得
            let dataLength = data.length

            //dataLengthまで以下の処理を繰り返す
            for(let i = 0; i <= initialWorks; i++){

                // 要素を作る
                const liELe = document.createElement("li")
                const aELe = document.createElement("a")
                const imgELe = document.createElement("img")
                const h4Ele = document.createElement("h4")
                const pEle = document.createElement("p")

                //pタグのテキストを挿入する
                pEle.textContent = data[i].company
                //h4タグのテキストを挿入する
                h4Ele.textContent = data[i].title
                //imgタグのpathを挿入する
                imgELe.src = data[i].thumbnail
                //aタグにモーダル用のclassを追加する
                aELe.classList.add('works-modal-open')
                //aタグに順番のためのidを付与する
                aELe.id = i

                aELe.href = "#"
                //aタグにappendする
                aELe.appendChild(imgELe)
                aELe.appendChild(h4Ele)
                aELe.appendChild(pEle)
                //liタグにaタグをappendする
                liELe.appendChild(aELe)
                //liをulにappendする
                ul.appendChild(liELe)

            }
        })
        .catch(err => console.log(err))
    }



    //moreボタンを押した時の処理
    if(!moreWorksButton){return}
    moreWorksButton.addEventListener('click', ()=>{


        //JSONファイルからworksを取得
        fetch(url)
        .then(response => response.json())
        .then(data => {

            //lengthを取得
            let dataLength = data.length

            //dataLengthまで以下の処理を繰り返す
            for(let i = initialWorks+1; i <= initialWorks+moreWorks; i++){

                // 要素を作る
                const liELe = document.createElement("li")
                const aELe = document.createElement("a")
                const imgELe = document.createElement("img")
                const h4Ele = document.createElement("h4")
                const pEle = document.createElement("p")

                //pタグのテキストを挿入する
                pEle.textContent = data[i].company
                //h4タグのテキストを挿入する
                h4Ele.textContent = data[i].title
                //imgタグのpathを挿入する
                imgELe.src = data[i].thumbnail
                //aタグにモーダル用のclassを追加する
                aELe.classList.add('works-modal-open')
                //aタグに順番のためのidを付与する
                aELe.id = i

                aELe.href = "#"
                //aタグにappendする
                aELe.appendChild(imgELe)
                aELe.appendChild(h4Ele)
                aELe.appendChild(pEle)
                //liタグにaタグをappendする
                liELe.appendChild(aELe)
                //liをulにappendする
                ul.appendChild(liELe)


                //iがnewsNewsDataLengthを越えたらmoreボタンを消す
                if( i === dataLength-1){
                    $(function () {
                        $('.page-works-more').fadeOut();
                    });

                }

            }
        })
        .catch(err => console.log(err))

        const countUp = () => {
            initialWorks = initialWorks+moreWorks

        }
        setTimeout(countUp, 1000)
        
    })





}